import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { GetImageRequestType } from '../types';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getImage(id: string, requestType: GetImageRequestType): Observable<Blob> {
        const url = this.getImageRequestUrl(id, requestType);

        return this.httpClient.addResponseCaching().skipErrorHandler().get(url, { responseType: 'blob' });
    }

    getAssetsImage(value: string): Observable<Blob> {
        const url = `${environment.assetsUrl}api/v1${value}`;

        return this.httpClient.addResponseCaching().skipApiPrefix().get(url, { responseType: 'blob' });
    }

    private getImageRequestUrl(id: string, requestType: GetImageRequestType) {
        switch (requestType) {
            case 'default':
                return `api/v1/media/image/${id}`;

            case 'public-image':
                return `api/public/v1/items/${id}/image`;
        }
    }
}
