import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, mergeMap } from 'rxjs';
import { mediaActions } from '../actions/media.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class MediaEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);

    getImage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(mediaActions.getImage),
            mergeMap(({ id, requestType }) => {
                return this.httpService.getImage(id, requestType).pipe(
                    map((blob) => mediaActions.getImageSuccess({ id, blob })),
                    catchError(() => of(mediaActions.getImageError({ id }))),
                );
            }, 4),
        );
    });

    getAssetsImage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(mediaActions.getAssetsImage),
            mergeMap(({ id }) => {
                return this.httpService.getAssetsImage(id).pipe(
                    map((blob) => mediaActions.getAssetsImageSuccess({ id, blob })),
                    catchError(() => of(mediaActions.getAssetsImageError({ id }))),
                );
            }, 2),
        );
    });
}
